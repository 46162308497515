import React from "react";
import { Box } from "@chakra-ui/react";

function DeleteSvg() {
  return (
    <Box
      as='svg'
      role='img'
      width='26px'
      height='26px'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M12.2261 2.4881C9.96238 2.37358 8.07022 3.99469 7.75521 6.16044C7.71546 6.43371 7.90477 6.68746 8.17804 6.7272C8.4513 6.76695 8.70505 6.57764 8.7448 6.30438C8.98713 4.6383 10.4385 3.39813 12.1772 3.48691L12.1814 3.4871C13.7184 3.55259 14.989 4.78448 15.2575 6.3186C15.3051 6.59061 15.5642 6.77253 15.8362 6.72492C16.1082 6.67732 16.2901 6.41822 16.2425 6.14622C15.903 4.2062 14.2861 2.57694 12.2261 2.4881Z'
        fill='currentColor'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M7.00108 8.45931L8.36991 19.3104C8.43667 19.7062 8.7649 19.9822 9.125 19.9822H14.9C15.2738 19.9822 15.5827 19.7198 15.6283 19.3249L15.629 19.3191C15.8164 17.8444 16.1602 15.1197 16.457 12.7634L16.9991 8.45807C16.9887 8.32251 16.8907 8.23218 16.75 8.23218H7.25C7.1198 8.23218 7.01286 8.33464 7.00108 8.45931ZM17.9961 8.54458L18 8.5135V8.48218C18 7.78104 17.4511 7.23218 16.75 7.23218H7.25C6.53808 7.23218 6 7.81693 6 8.48218V8.51359L7.37997 19.453L7.38128 19.4612C7.51733 20.3115 8.2373 20.9822 9.125 20.9822H14.9C15.7752 20.9822 16.5155 20.346 16.6213 19.4427C16.8089 17.9667 17.1525 15.2433 17.4492 12.8884L17.9961 8.54458Z'
        fill='currentColor'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M9.875 10.4832C10.1511 10.4832 10.375 10.707 10.375 10.9832V16.9832C10.375 17.2593 10.1511 17.4832 9.875 17.4832C9.59886 17.4832 9.375 17.2593 9.375 16.9832V10.9832C9.375 10.707 9.59886 10.4832 9.875 10.4832Z'
        fill='currentColor'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M14.125 10.4832C14.4011 10.4832 14.625 10.707 14.625 10.9832V16.9832C14.625 17.2593 14.4011 17.4832 14.125 17.4832C13.8489 17.4832 13.625 17.2593 13.625 16.9832V10.9832C13.625 10.707 13.8489 10.4832 14.125 10.4832Z'
        fill='currentColor'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M12 10.4832C12.2761 10.4832 12.5 10.707 12.5 10.9832V16.9832C12.5 17.2593 12.2761 17.4832 12 17.4832C11.7239 17.4832 11.5 17.2593 11.5 16.9832V10.9832C11.5 10.707 11.7239 10.4832 12 10.4832Z'
        fill='currentColor'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M4.5 7.73218C4.5 7.45604 4.72386 7.23218 5 7.23218H19C19.2761 7.23218 19.5 7.45604 19.5 7.73218C19.5 8.00832 19.2761 8.23218 19 8.23218H5C4.72386 8.23218 4.5 8.00832 4.5 7.73218Z'
        fill='currentColor'
      />
    </Box>
  );
}

export default DeleteSvg;
