import React from "react";
import { Box } from "@chakra-ui/react";

function UploadSvg() {
  return (
    <Box
      as='svg'
      role='img'
      width='24px'
      height='24px'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M11.2857 5.84137C11.6803 5.45327 12.2924 5.45327 12.687 5.84137L15.5869 8.69383C15.7838 8.88747 15.7864 9.20404 15.5928 9.40091C15.3991 9.59778 15.0826 9.6004 14.8857 9.40676L11.9863 6.55491L9.08695 9.40676C8.89008 9.6004 8.57351 9.59778 8.37986 9.40091C8.18622 9.20404 8.18884 8.88747 8.38571 8.69383L11.2857 5.84137Z'
        fill='CurrentColor'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M12 5.72559C12.2761 5.72559 12.5 5.94944 12.5 6.22559V13.9756C12.5 14.2517 12.2761 14.4756 12 14.4756C11.7239 14.4756 11.5 14.2517 11.5 13.9756V6.22559C11.5 5.94944 11.7239 5.72559 12 5.72559Z'
        fill='CurrentColor'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M6.979 13.5083C7.25515 13.5083 7.479 13.7322 7.479 14.0083V16.7583C7.479 17.1822 7.80515 17.5083 8.229 17.5083H15.729C16.1529 17.5083 16.479 17.1822 16.479 16.7583V14.0083C16.479 13.7322 16.7029 13.5083 16.979 13.5083C17.2551 13.5083 17.479 13.7322 17.479 14.0083V16.7583C17.479 17.7344 16.7051 18.5083 15.729 18.5083H8.229C7.25286 18.5083 6.479 17.7344 6.479 16.7583V14.0083C6.479 13.7322 6.70286 13.5083 6.979 13.5083Z'
        fill='CurrentColor'
      />
    </Box>
  );
}

export default UploadSvg;
